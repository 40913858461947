.main {
  margin: 0 auto;
  padding: 95px 0 45px;
}

.main__title {
  font-family: "Gilroy", sans-serif;
  font-size: 36px;
  font-weight: 800;
  line-height: 45px;
  text-align: center;
  color: #da1f62;
  text-transform: uppercase;
}

.main__thumb {
  display: flex;
  flex-direction: column;
  gap: 22px;
  margin-top: 22px;
}

.main__item {
  display: flex;
  justify-content: start;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  max-width: 392px;
  width: 100%;
  height: 313px;
  padding: 10px;
  border-radius: 15px;
  border: 1px solid #ffffff66;
}

.main__raiting {
  max-width: 386px;
  width: 100%;
  margin: 0 auto;
}

.main__text--top {
  font-family: "Gilroy", sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
}

.main__text--bottom {
  margin-top: -14px;
  font-family: "Gilroy", sans-serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 29.9px;
  text-align: center;
  color: #ffffff;
}

.main__prize--list {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 6px;
}

.main__prize--item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 160px;
  width: 100%;
  height: 57px;
  border-radius: 8px;
  background-color: #1a1f33cc;
}

.main__button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 372px;
  width: 100%;
  height: 90px;
  border-radius: 12px;
  background: linear-gradient(180deg, #f9cc6a 0%, #fcff88 100%);

  border: transparent;
  font-family: "Gilroy", sans-serif;
  font-size: 28px;
  font-weight: 800;
  line-height: 32.68px;
  text-align: center;
  color: #835a00;

  text-transform: uppercase;
  cursor: pointer;
}

.main__fade--text {
  font-family: "Gilroy", sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 23.1px;
  text-align: center;
  color: #ffffff;
}

.main__fade {
  max-width: 366px;
  width: 100%;
  height: 25px;
  background: radial-gradient(
    50% 15689.8% at 50% 50%,
    #d11111 0%,
    rgba(209, 17, 17, 0) 100%
  );
}

@media screen and (max-width: 350px) {
  .main__prize--top {
    font-size: 21px;
  }

  .main__prize--bottom {
    font-size: 13px;
  }

  .main__title {
    font-size: 32px;
  }
}

@media screen and (max-width: 330px) {
  .main__text--topSmaller {
    font-size: 39px;
  }
}

@media screen and (min-width: 1000px) {
  .main__thumb {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
}
