.header {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-image: url("../assets/header.png");
  height: 74px;
}

// .header__wrapper {
//   width: 100%;
//   position: relative;
// }

.header__logo {
  position: absolute;
  height: 54px;
  transform: translateX(-50%);
  margin: 0 auto;
  z-index: 2;
  top: 10px;
  left: 50%;
}

.header__svg--mob {
  position: absolute;
  margin: 0 auto;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.header__svg--pc {
  display: none;
}

@media screen and (min-width: 1000px) {
  .header__svg--pc {
    display: block;
    position: absolute;
    margin: 0 auto;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
  }

  .header__svg--mob {
    display: none;
  }

  .header {
    background-image: url("../assets/headerPC.png");
  }
}
